import { Button, ButtonProps } from '@mui/material';
import { LINE_HEIGHT } from '../../typography/Typography';

const buttonBorderRadius = '5px';

const outlinedSecondaryButtonStyles: React.CSSProperties = {
    padding: '0px 12px',
    margin: '2px',
    height: '24px',
    borderRadius: buttonBorderRadius,
    textTransform: 'none',
    lineHeight: LINE_HEIGHT.SMALL,
    border: '1px solid #D0D5DD',
    color: '#344054',
    fontWeight: 600,
    boxShadow: '0px 1px 2px 0px #1018280D'
};

const secondaryButtonRegularStyles: React.CSSProperties = {
    ...outlinedSecondaryButtonStyles,
    height: '36px',
    padding: '10px 16px 10px 16px',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 2px 0px #1018280d',
    border: '1px solid #D0D5DD'
};

export const OutlinedSecondaryButton = (props: ButtonProps) => {
    return (
        <Button
            style={outlinedSecondaryButtonStyles}
            {...props}
            variant="outlined"
        >
            {props.children}
        </Button>
    );
};

export const OutlinedSecondaryRegular = (props: ButtonProps) => {
    return (
        <Button
            style={outlinedSecondaryButtonStyles}
            {...props}
            variant="outlined"
        >
            {props.children}
        </Button>
    );
};

export const SecondaryButtonRegular = (props: ButtonProps) => {
    return (
        <Button
            style={{
                ...secondaryButtonRegularStyles,
                height: props?.size == 'small' ? '24px' : '36px',
                borderRadius: props.size == 'small' ? '5px' : '8px'
            }}
            {...props}
            variant="outlined"
        >
            {props.children}
        </Button>
    );
};

export const OutlinedPrimaryButton = (props: ButtonProps) => {
    const outlinedPrimaryButtonStyles: React.CSSProperties = {
        borderRadius: props.size == 'small' ? '5px' : '8px',
        textTransform: 'none',
        backgroundColor: '#EFF8FF',
        height: props.size == 'small' ? '24px' : '36px',
        border: '1px solid #1570EF',
        color: '#1570EF',
        fontWeight: 600,
        lineHeight:
            props.size == 'small' ? LINE_HEIGHT.SMALL : LINE_HEIGHT.MEDIUM
    };

    return (
        <Button
            style={outlinedPrimaryButtonStyles}
            {...props}
            variant="outlined"
        >
            {props.children}
        </Button>
    );
};

export const PrimaryButton = ({ style, ...props }: ButtonProps) => {
    const primaryButtonStyles: React.CSSProperties = {
        margin: '2px',
        borderRadius: props.size == 'small' ? '5px' : '8px',
        textTransform: 'none',
        height: props.size == 'small' ? '24px' : '36px',
        padding: '4px 14px 4px 14px',
        fontWeight: 600,
        lineHeight:
            props.size == 'small' ? LINE_HEIGHT.SMALL : LINE_HEIGHT.MEDIUM
    };
    return (
        <Button
            disableElevation
            style={{ ...primaryButtonStyles, ...style }}
            {...props}
            variant="contained"
        >
            {props.children}
        </Button>
    );
};

export const PrimaryInvertedButton = ({ style, ...props }: ButtonProps) => {
    const primaryButtonStyles: React.CSSProperties = {
        borderRadius: props.size == 'small' ? '5px' : '8px',
        textTransform: 'none',
        height: props.size == 'small' ? '24px' : '44px',
        fontWeight: 600,
        backgroundColor: '#EFF8FF',
        color: '#1570EF',
        lineHeight:
            props.size == 'small' ? LINE_HEIGHT.SMALL : LINE_HEIGHT.MEDIUM
    };

    return (
        <Button
            disableElevation
            style={{ ...primaryButtonStyles, ...style }}
            {...props}
            variant="contained"
        >
            {props.children}
        </Button>
    );
};
