import {
    Table,
    TableBody,
    TableContainer,
    Button,
    Alert,
    Menu,
    MenuItem,
    useTheme,
    Card,
    Stack
} from '@mui/material';
import { useUser } from '../../contexts/user/UserContext';
import {
    IntectUserDetailed,
    PaymentsUser,
    UserDetailed,
    ZenegyUserDetailed
} from '../../models/payment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import Chip, { chipColors } from '../../components/shared/Chip';
import {
    TableCell,
    TableCellNoPadding,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellWithCheckbox,
    TableRow,
    TableSortLabel
} from '../../components/shared/styles/Table';
import { IOSSwitch } from '../../components/shared/Switch';
import { TablePagination } from '../../components/shared/TablePagination';
import { SMBold } from '../../typography/Typography';
import CardHeader from '../../components/shared/CardHeader';
import { Dropdown, DropdownType } from '../../components/shared/Dropdown';
import { Checkbox } from '../../components/shared/CheckBox';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { UserTimeRegistrationRecordDetails } from './UserTimeRegistrationRecordDetails';
import { UserRoles } from '../../components/sidebar/MenuTree';
import { EditUserDialog } from '../company/EditUsersDialog';

export enum UserTableType {
    Zenergy = 'zenergy',
    Intect = 'intect'
}

export type UsersTableProps<T = UserDetailed> = {
    usersDetailed: any;
    checked: string[];
    allChecked: boolean;
    onStatusClick: (userId: string, isActive: boolean) => void;
    onRoleToggleChange: (userId: string, isAdmin: boolean) => void;
    onRowClick: (userId: string) => () => void;
    onCheckAllClick: () => () => void;
    onEditClick: (userId: string) => () => void;
    onUpdateBankAccountDetailsClick: (userId: string) => () => void;
    onSendActivationMailClicked: (userId: string, name: string) => void;
    handleBulkEnable?: (checked: string[]) => void;
    handleBulkDisable?: (checked: string[]) => void;
    handleDeleteUser?: (userId: string) => void;
    total?: number;
    handleChangePage?: (
        event: React.MouseEvent<HTMLButtonElement> | null,
        page: number
    ) => void;
    handleChangeRowsPerPage?: (rowsPerPage: number) => void;
    rowsPerPage: number;
    userTableType: UserTableType;
    setChecked?: any;
    displaySettingUser?: boolean;
    addUserForm?: any;
    onReloadUser: any;
};

type SortingType = 'asc' | 'desc' | undefined;

type KeysPaymentsUser = keyof ZenegyUserDetailed & keyof IntectUserDetailed;

export const UsersTable = ({
    usersDetailed,
    total,
    handleBulkEnable,
    handleBulkDisable,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    handleDeleteUser,
    userTableType,
    checked,
    setChecked,
    displaySettingUser,
    addUserForm,
    ...props
}: UsersTableProps) => {
    const { user } = useUser();
    const [checkedMap, setCheckedMap] = useState<{ [key: string]: boolean }>(
        {}
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [rowUser, setRowUser] = useState<PaymentsUser>();

    const [editUserOpen, setEditUserOpen] = useState(false);

    useEffect(() => {
        let map = {} as { [key: string]: boolean };

        checked.forEach((userId) => {
            map[userId] = true;
        });
        setCheckedMap(map);
    }, [checked]);

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        rowUser: PaymentsUser
    ) => {
        setAnchorEl(event.currentTarget);
        setRowUser(rowUser);
        setChecked([rowUser?.userId]);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setChecked([]);
    };

    const theme = useTheme();

    const getStatusView = (
        id: string,
        isActive: boolean,
        isDisabled: boolean
    ) => {
        let text = 'Imported';
        let color = 'muted';

        if (isActive && !isDisabled) {
            text = 'Enabled';
            color = 'success';
        }
        if (!isActive && isDisabled) {
            text = 'Disabled';
            color = 'error';
        }

        return <Chip label={text} color={color as any} />;
    };

    const { t } = useTranslation();

    const getStatusButton = (id: string, isActive: boolean) => {
        const color = isActive ? 'success' : 'error';
        const text = isActive ? 'Disable' : 'Enable';

        return (
            <div
                style={{ width: '100%' }}
                onClick={() => {
                    handleMenuClose();
                    props.onStatusClick(id, !isActive);
                }}
                color={color}
            >
                {text}
            </div>
        );
    };

    const userList = useMemo(() => {
        if (userTableType == UserTableType.Zenergy) {
            return !displaySettingUser
                ? usersDetailed.users
                : (usersDetailed?.companySettingsUsers as ZenegyUserDetailed[]);
        } else {
            return !displaySettingUser
                ? usersDetailed.users
                : (usersDetailed?.companySettingsUsers as IntectUserDetailed[]);
        }
    }, [usersDetailed.users, usersDetailed?.companySettingsUsers]);

    const renderCheckBox = useCallback(
        (rowUser: UserDetailed) => {
            return (
                <Checkbox
                    sx={{
                        marginLeft: '10px',
                        padding: 0
                    }}
                    checked={checkedMap[rowUser.userId] ?? false}
                    inputProps={{
                        'aria-labelledby': `checkbox-list-secondary-label-${rowUser.userId}`
                    }}
                />
            );
        },
        [checkedMap]
    );

    const [nameSortDirection, setNameSortDirection] =
        useState<SortingType>(undefined);
    const [emailSortDirection, setEmailSortDirection] =
        useState<SortingType>(undefined);
    const [mobileSortDirection, setMobileSortDirection] =
        useState<SortingType>(undefined);
    const [
        salaryInstapaidTypeSortDirection,
        setSalaryInstapaidTypeSortDirection
    ] = useState<SortingType>(undefined);
    const [departmentSortDirection, setDepartmentSortDirection] =
        useState<SortingType>(undefined);
    const [employeeTypeSortDirection, setEmployeeTypeSortDirection] =
        useState<SortingType>(undefined);
    const [
        employmentTemplateSortDirection,
        setEmploymentTemplateSortDirection
    ] = useState<SortingType>(undefined);
    const [employmentTypeSortDirection, setEmploymentTypeSortDirection] =
        useState<SortingType>(undefined);
    const [salaryTypeSortDirection, setSalaryTypeSortDirection] =
        useState<SortingType>(undefined);
    const [statusSortDirection, setStatusSortDirection] =
        useState<SortingType>(undefined);
    const [
        timeRegistrationIdSortDirection,
        setTimeRegistrationIdSortDirection
    ] = useState<SortingType>(undefined);
    const [adminSortDirection, setAdminSortDirection] =
        useState<SortingType>(undefined);
    const [accessTypeSortDirection, setAccessTypeSortDirection] =
        useState<SortingType>(undefined);

    const sortedUserList = useMemo(() => {
        let list = [...userList];

        const sortArr = [
            {
                key: 'firstName' as KeysPaymentsUser,
                direction: nameSortDirection
            },
            {
                key: 'email' as KeysPaymentsUser,
                direction: emailSortDirection
            },
            {
                key: 'phoneNumber' as KeysPaymentsUser,
                direction: departmentSortDirection
            },
            {
                key: 'departmentName' as KeysPaymentsUser,
                direction: departmentSortDirection
            },
            {
                key: 'employeeType' as KeysPaymentsUser,
                direction: employeeTypeSortDirection
            },
            {
                key: 'employmentTemplateName' as KeysPaymentsUser,
                direction: employmentTemplateSortDirection
            },
            {
                key: 'plandayEmployeeTypeName' as KeysPaymentsUser,
                direction: employmentTypeSortDirection
            },
            {
                key: 'salaryType' as KeysPaymentsUser,
                direction: salaryTypeSortDirection
            },
            {
                key: 'isActive' as KeysPaymentsUser,
                direction: statusSortDirection
            },
            {
                key: 'timeRegistrationProviderId' as KeysPaymentsUser,
                direction: timeRegistrationIdSortDirection
            },
            {
                key: 'role' as KeysPaymentsUser,
                direction: adminSortDirection
            },
            {
                key: 'instapaidSalaryType' as KeysPaymentsUser,
                direction: salaryInstapaidTypeSortDirection
            },
            {
                key: 'access' as KeysPaymentsUser,
                direction: accessTypeSortDirection
            }
        ];

        sortArr.forEach((sortDirection) => {
            if (sortDirection.direction) {
                list.sort((a, b) => {
                    if (a[sortDirection.key] && b[sortDirection.key]) {
                        return (
                            a[sortDirection.key]!.toString().localeCompare(
                                b[sortDirection.key!]!.toString()
                            ) * (sortDirection.direction === 'asc' ? 1 : -1)
                        );
                    }
                    return 0;
                });
            }
        });

        return list;
    }, [
        userList,
        nameSortDirection,
        emailSortDirection,
        mobileSortDirection,
        departmentSortDirection,
        employeeTypeSortDirection,
        employmentTemplateSortDirection,
        employmentTypeSortDirection,
        salaryTypeSortDirection,
        statusSortDirection,
        timeRegistrationIdSortDirection,
        adminSortDirection,
        salaryInstapaidTypeSortDirection,
        accessTypeSortDirection
    ]);

    const handleSort = useCallback(
        (key: SortingType, func: Function) => {
            setNameSortDirection(undefined);
            setEmailSortDirection(undefined);
            setDepartmentSortDirection(undefined);
            setEmployeeTypeSortDirection(undefined);
            setEmploymentTemplateSortDirection(undefined);
            setEmploymentTypeSortDirection(undefined);
            setSalaryTypeSortDirection(undefined);
            setStatusSortDirection(undefined);
            setTimeRegistrationIdSortDirection(undefined);
            setAdminSortDirection(undefined);
            setSalaryInstapaidTypeSortDirection(undefined);
            setAccessTypeSortDirection(undefined);

            key === 'asc' ? func('desc') : func('asc');
        },
        [
            userList,
            nameSortDirection,
            emailSortDirection,
            mobileSortDirection,
            departmentSortDirection,
            employeeTypeSortDirection,
            employmentTemplateSortDirection,
            employmentTypeSortDirection,
            salaryTypeSortDirection,
            statusSortDirection,
            timeRegistrationIdSortDirection,
            adminSortDirection,
            salaryInstapaidTypeSortDirection,
            accessTypeSortDirection
        ]
    );

    const onHandleBulkEnableOrDisable = useCallback(
        (value: string) => {
            if (value === 'Enable') {
                handleBulkEnable?.(checked);
            }
            if (value === 'Disable') {
                handleBulkDisable?.(checked);
            }
        },
        [checked, handleBulkEnable]
    );

    const [action, setAction] = useState<string>('');

    const actions = (
        <Stack direction="row">
            {/* <OutlinedSecondaryButton>Actions</OutlinedSecondaryButton> */}
            <Dropdown
                type={DropdownType.Action}
                label="Action"
                listContainerStyle={{
                    left: '-40px',
                    width: '200px'
                }}
                choices={[
                    {
                        label: 'Enable Selected',
                        value: 'Enable'
                    },
                    {
                        label: 'Disable Selected',
                        value: 'Disable'
                    }
                ]}
                value={''}
                onChange={onHandleBulkEnableOrDisable}
            />
        </Stack>
    );

    const navigation = useNavigate();
    const navigateToUserDetails = (userId: string) => {
        navigation(`/users/${userId}`);
    };

    return (
        <>
            <Card>
                <CardHeader
                    label={t('list')}
                    chip={
                        <Chip
                            icon={<></>}
                            label={
                                <SMBold color={chipColors.success.text}>
                                    {!displaySettingUser
                                        ? `${userList.length} of ${total}`
                                        : `${sortedUserList.length} of ${usersDetailed.companySettingsUsers?.length}`}
                                </SMBold>
                            }
                            color={'success'}
                        />
                    }
                    actions={displaySettingUser ? addUserForm : actions}
                />
                <TableContainer style={{ minWidth: '1424px' }}>
                    {userList.length > 0 ? (
                        <Table sx={{}} aria-label="simple table">
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>
                                        <Checkbox
                                            onClick={props.onCheckAllClick()}
                                            sx={{
                                                marginLeft: '10px',
                                                padding: 0
                                            }}
                                            edge="start"
                                            checked={props.allChecked}
                                            tabIndex={-1}
                                            inputProps={{
                                                style: {
                                                    borderRadius: '4px'
                                                },
                                                'aria-labelledby':
                                                    'checkbox-list-secondary-label-all'
                                            }}
                                        />
                                    </TableHeaderCell>
                                    <TableHeaderCellWithCheckbox>
                                        <TableSortLabel
                                            direction={nameSortDirection}
                                            onClick={() =>
                                                handleSort(
                                                    nameSortDirection,
                                                    setNameSortDirection
                                                )
                                            }
                                        >
                                            {t('name')}
                                        </TableSortLabel>
                                    </TableHeaderCellWithCheckbox>
                                    <TableHeaderCellWithCheckbox>
                                        <TableSortLabel
                                            direction={emailSortDirection}
                                            onClick={() =>
                                                handleSort(
                                                    emailSortDirection,
                                                    setEmailSortDirection
                                                )
                                            }
                                        >
                                            {t('email')}
                                        </TableSortLabel>
                                    </TableHeaderCellWithCheckbox>
                                    <TableHeaderCellWithCheckbox>
                                        <TableSortLabel
                                            direction={mobileSortDirection}
                                            onClick={() =>
                                                handleSort(
                                                    mobileSortDirection,
                                                    setMobileSortDirection
                                                )
                                            }
                                        >
                                            {t('mobile')}
                                        </TableSortLabel>
                                    </TableHeaderCellWithCheckbox>
                                    {!displaySettingUser && (
                                        <>
                                            <TableHeaderCellWithCheckbox
                                                sx={{
                                                    minWidth: '120px'
                                                }}
                                            >
                                                <TableSortLabel
                                                    direction={
                                                        salaryInstapaidTypeSortDirection
                                                    }
                                                    onClick={() =>
                                                        handleSort(
                                                            salaryInstapaidTypeSortDirection,
                                                            setSalaryInstapaidTypeSortDirection
                                                        )
                                                    }
                                                >
                                                    {t('salary_type')}
                                                </TableSortLabel>
                                            </TableHeaderCellWithCheckbox>
                                            <TableHeaderCellWithCheckbox>
                                                <TableSortLabel
                                                    direction={
                                                        departmentSortDirection
                                                    }
                                                    onClick={() =>
                                                        handleSort(
                                                            departmentSortDirection,
                                                            setDepartmentSortDirection
                                                        )
                                                    }
                                                >
                                                    {t('department')}
                                                </TableSortLabel>
                                            </TableHeaderCellWithCheckbox>
                                            {userTableType ==
                                                UserTableType.Zenergy && (
                                                <TableHeaderCellWithCheckbox>
                                                    <TableSortLabel
                                                        direction={
                                                            employeeTypeSortDirection
                                                        }
                                                        onClick={() =>
                                                            handleSort(
                                                                employeeTypeSortDirection,
                                                                setEmployeeTypeSortDirection
                                                            )
                                                        }
                                                    >
                                                        {t('employee_type')}
                                                    </TableSortLabel>
                                                </TableHeaderCellWithCheckbox>
                                            )}

                                            {userTableType ==
                                                UserTableType.Intect && (
                                                <TableHeaderCellWithCheckbox>
                                                    <TableSortLabel
                                                        direction={
                                                            employmentTypeSortDirection
                                                        }
                                                        onClick={() =>
                                                            handleSort(
                                                                employmentTypeSortDirection,
                                                                setEmploymentTypeSortDirection
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            'employment_template'
                                                        )}
                                                    </TableSortLabel>
                                                </TableHeaderCellWithCheckbox>
                                            )}
                                            <TableHeaderCellWithCheckbox
                                                sx={{
                                                    minWidth: '140px'
                                                }}
                                            >
                                                <TableSortLabel
                                                    direction={
                                                        employmentTypeSortDirection
                                                    }
                                                    onClick={() =>
                                                        handleSort(
                                                            employmentTypeSortDirection,
                                                            setEmploymentTypeSortDirection
                                                        )
                                                    }
                                                >
                                                    {t('employment_type')}
                                                </TableSortLabel>
                                            </TableHeaderCellWithCheckbox>
                                            {userTableType ==
                                                UserTableType.Zenergy && (
                                                <TableHeaderCellWithCheckbox>
                                                    <TableSortLabel
                                                        direction={
                                                            salaryTypeSortDirection
                                                        }
                                                        onClick={() =>
                                                            handleSort(
                                                                salaryTypeSortDirection,
                                                                setSalaryTypeSortDirection
                                                            )
                                                        }
                                                    >
                                                        {t('salary_type') +
                                                            ' (Zenergy)'}
                                                    </TableSortLabel>
                                                </TableHeaderCellWithCheckbox>
                                            )}
                                        </>
                                    )}

                                    {displaySettingUser && (
                                        <TableHeaderCellWithCheckbox>
                                            <TableSortLabel
                                                direction={
                                                    accessTypeSortDirection
                                                }
                                                onClick={() =>
                                                    handleSort(
                                                        accessTypeSortDirection,
                                                        setAccessTypeSortDirection
                                                    )
                                                }
                                            >
                                                {t('access_type')}
                                            </TableSortLabel>
                                        </TableHeaderCellWithCheckbox>
                                    )}

                                    <TableHeaderCellWithCheckbox>
                                        {t('status')}
                                    </TableHeaderCellWithCheckbox>
                                    {!displaySettingUser && (
                                        <>
                                            <TableHeaderCellWithCheckbox>
                                                <TableSortLabel
                                                    direction={
                                                        timeRegistrationIdSortDirection
                                                    }
                                                    onClick={() =>
                                                        handleSort(
                                                            timeRegistrationIdSortDirection,
                                                            setTimeRegistrationIdSortDirection
                                                        )
                                                    }
                                                >
                                                    {t('time_registration_id')}
                                                </TableSortLabel>
                                            </TableHeaderCellWithCheckbox>
                                            <TableHeaderCellWithCheckbox>
                                                <TableSortLabel
                                                    direction={
                                                        adminSortDirection
                                                    }
                                                    onClick={() =>
                                                        handleSort(
                                                            adminSortDirection,
                                                            setAdminSortDirection
                                                        )
                                                    }
                                                >
                                                    {t('admin')}
                                                </TableSortLabel>
                                            </TableHeaderCellWithCheckbox>
                                        </>
                                    )}

                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {sortedUserList?.map((rowUser) => (
                                    <TableRow
                                        style={{
                                            backgroundColor: checkedMap[
                                                rowUser.userId
                                            ]
                                                ? theme.palette.selectedRowColor
                                                : 'inherit'
                                        }}
                                        key={rowUser.userId}
                                        // onClick={props.onRowClick(rowUser.userId)}
                                    >
                                        <TableCell>
                                            {rowUser.userId !== user.id &&
                                                renderCheckBox(rowUser)}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() => {
                                                navigateToUserDetails(
                                                    rowUser.userId
                                                );
                                                props.onRowClick(
                                                    rowUser.userId
                                                );
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }}
                                        >
                                            {rowUser.firstName}{' '}
                                            {rowUser.lastName}
                                        </TableCell>
                                        <TableCell
                                            onClick={props.onRowClick(
                                                rowUser.userId
                                            )}
                                        >
                                            {rowUser.email}
                                        </TableCell>
                                        <TableCell
                                            onClick={props.onRowClick(
                                                rowUser.userId
                                            )}
                                        >
                                            {rowUser.phoneNumber}
                                        </TableCell>
                                        {!displaySettingUser && (
                                            <>
                                                <TableCell>
                                                    {
                                                        rowUser.instapaidSalaryType
                                                    }
                                                </TableCell>
                                                {
                                                    <TableCell
                                                        onClick={props.onRowClick(
                                                            rowUser.userId
                                                        )}
                                                    >
                                                        {!(
                                                            rowUser as ZenegyUserDetailed
                                                        ).userExistsInZenegy &&
                                                            userTableType ==
                                                                UserTableType.Zenergy && (
                                                                <LinkOffIcon titleAccess="This employee does not have a record in Zenegy and won't be able to use the App" />
                                                            )}
                                                        {rowUser.departmentName}
                                                    </TableCell>
                                                }

                                                {userTableType ==
                                                    UserTableType.Zenergy && (
                                                    <TableCell
                                                        onClick={props.onRowClick(
                                                            rowUser.userId
                                                        )}
                                                    >
                                                        {
                                                            (
                                                                rowUser as ZenegyUserDetailed
                                                            ).employeeType
                                                        }
                                                    </TableCell>
                                                )}
                                                {userTableType ==
                                                    UserTableType.Intect && (
                                                    <TableCell
                                                        onClick={props.onRowClick(
                                                            rowUser.userId
                                                        )}
                                                    >
                                                        {
                                                            (
                                                                rowUser as IntectUserDetailed
                                                            )
                                                                .employmentTemplateName
                                                        }
                                                    </TableCell>
                                                )}

                                                <TableCell
                                                    onClick={props.onRowClick(
                                                        rowUser.userId
                                                    )}
                                                >
                                                    {
                                                        rowUser.plandayEmployeeTypeName
                                                    }
                                                </TableCell>
                                                {userTableType ==
                                                    UserTableType.Zenergy && (
                                                    <TableCell
                                                        onClick={props.onRowClick(
                                                            rowUser.userId
                                                        )}
                                                    >
                                                        {
                                                            (
                                                                rowUser as ZenegyUserDetailed
                                                            ).salaryType
                                                        }
                                                    </TableCell>
                                                )}
                                            </>
                                        )}
                                        {displaySettingUser && (
                                            <TableCell
                                                onClick={props.onRowClick(
                                                    rowUser.userId
                                                )}
                                            >
                                                {rowUser.role}
                                            </TableCell>
                                        )}
                                        <TableCell
                                            onClick={props.onRowClick(
                                                rowUser.userId
                                            )}
                                        >
                                            {getStatusView(
                                                rowUser.userId,
                                                rowUser.isActive,
                                                rowUser.isDisabled
                                            )}
                                        </TableCell>
                                        {!displaySettingUser && (
                                            <>
                                                <TableCell
                                                    onClick={props.onRowClick(
                                                        rowUser.userId
                                                    )}
                                                >
                                                    {
                                                        <UserTimeRegistrationRecordDetails
                                                            timeRegistrationProviderId={
                                                                rowUser.timeRegistrationProviderId
                                                            }
                                                        />
                                                    }
                                                </TableCell>

                                                <TableCell
                                                    onClick={props.onRowClick(
                                                        rowUser.userId
                                                    )}
                                                >
                                                    <IOSSwitch
                                                        disabled={
                                                            rowUser.userId ===
                                                            user.id
                                                        }
                                                        onChange={(
                                                            event,
                                                            checked
                                                        ) => {
                                                            props.onRoleToggleChange(
                                                                rowUser.userId,
                                                                checked
                                                            );
                                                        }}
                                                        checked={
                                                            rowUser.role ===
                                                                'Admin' ||
                                                            rowUser.role ===
                                                                'SuperAdmin'
                                                        }
                                                    />
                                                </TableCell>
                                            </>
                                        )}

                                        <TableCellNoPadding>
                                            <Button
                                                color="secondary"
                                                aria-controls={
                                                    open
                                                        ? 'basic-menu'
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                    open ? 'true' : undefined
                                                }
                                                onClick={(event) => {
                                                    handleMenuClick(
                                                        event,
                                                        rowUser
                                                    );
                                                    props.onRowClick(
                                                        rowUser.userId
                                                    );
                                                    renderCheckBox(rowUser);
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </Button>
                                        </TableCellNoPadding>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Alert sx={{ marginTop: 1 }} severity="info">
                            The user list is empty.
                        </Alert>
                    )}

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem>
                            <>
                                {getStatusButton(
                                    rowUser?.userId!,
                                    rowUser?.isActive!
                                )}
                            </>
                        </MenuItem>

                        {!displaySettingUser ? (
                            <>
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}
                                    to={{
                                        pathname: `/users/${rowUser?.userId}`
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {}}
                                        sx={{ boxShadow: 'none' }}
                                    >
                                        {t('see_earnings')}
                                    </MenuItem>
                                </Link>

                                <MenuItem
                                    onClick={props.onEditClick(
                                        rowUser?.userId!
                                    )}
                                    aria-label="edit user"
                                >
                                    {t('edit_details')}
                                </MenuItem>

                                <MenuItem
                                    onClick={props.onUpdateBankAccountDetailsClick(
                                        rowUser?.userId!
                                    )}
                                    aria-label="edit bank account details"
                                >
                                    {t('edit_bank')}
                                </MenuItem>
                            </>
                        ) : (
                            <MenuItem
                                onClick={() => {
                                    setEditUserOpen(true);
                                    handleMenuClose();
                                }}
                                aria-label="edit user"
                            >
                                {t('edit_access')}
                            </MenuItem>
                        )}

                        <MenuItem
                            onClick={() =>
                                props.onSendActivationMailClicked(
                                    rowUser?.userId!,
                                    rowUser?.firstName + ' ' + rowUser?.lastName
                                )
                            }
                        >
                            {t('send_activation_mail')}
                        </MenuItem>

                        {(user.role === UserRoles.SuperAdmin ||
                            user.role === UserRoles.Admin) && (
                            <MenuItem
                                onClick={() => {
                                    handleDeleteUser?.(rowUser?.userId!);
                                    handleMenuClose();
                                }}
                            >
                                {!displaySettingUser
                                    ? t('delete_user')
                                    : t('remove')}
                            </MenuItem>
                        )}
                    </Menu>
                </TableContainer>
                <TablePagination
                    count={total!}
                    page={0}
                    onPageChange={() => {}}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage as any} //TODO: Fix this
                    rowsPerPageOptions={[
                        {
                            label: '16',
                            value: 16
                        },
                        {
                            label: '24',
                            value: 24
                        },
                        {
                            label: '32',
                            value: 32
                        },
                        {
                            label: 'All',
                            value: -1
                        }
                    ]}
                />
            </Card>

            <EditUserDialog
                dialogOpen={editUserOpen}
                onClose={() => setEditUserOpen(false)}
                rowData={editUserOpen && rowUser}
                refreshList={props.onReloadUser}
            />
        </>
    );
};
