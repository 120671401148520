import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    min-height: 100px;
    border-radius: 8px;
`;

export const StyledDynamicContentBox = styled.div`
    overflow-y: auto;
    max-height: 300px;
    padding: 8px 8px 0px 8px;
    border: 1px solid #d0d5dd;
`;
