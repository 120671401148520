import { Box } from '@mui/material';
import { NotificationsSnackbar } from './snackbar/NotificationsSnackbar';
import { Sidebar } from './sidebar/Sidebar';
import { useUser } from '../contexts/user/UserContext';
import { Consents } from './consent/Consents';
import { UserRoles } from './sidebar/MenuTree';

type PageLayoutProps = {
    children: JSX.Element;
};

export const PageLayout = (props: PageLayoutProps) => {
    const { company, user } = useUser();

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box
                component="main"
                sx={{ flexGrow: 1, margin: '20px', marginTop: '40px' }}
            >
                {company.requiredConsentsAccepted ||
                user.role == UserRoles.SuperAdmin ? (
                    <>{props.children}</>
                ) : (
                    <Consents />
                )}
            </Box>
            <NotificationsSnackbar />
        </Box>
    );
};
