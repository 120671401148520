import styled from '@emotion/styled';
import {
    TablePaginationProps as MuiTablePaginationProps,
    Pagination,
    PaginationItem,
    Stack
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DropDownChoices, Dropdown, DropdownType } from './Dropdown';

const StyledPageItem = styled(PaginationItem)(({ theme }) => {
    return {
        '&': {
            display: 'block',
            height: '40px',
            minWidth: '40px',
            textAlign: 'center',
            margin: '0px',
            borderRadius: '0px',
            padding: '10px 10px 10px 10px',
            textDecoration: 'none',
            border: '1px solid #D0D5DD',
            color: theme.palette.text.primary
        },
        '&.Mui-selected': {
            backgroundColor: '#EFF8FF',
            color: theme.palette.text.primary
        },
        '&.MuiPaginationItem-previousNext[aria-label="Go to previous page"]': {
            borderRadius: '8px 0 0 8px'
        },
        '&.MuiPaginationItem-previousNext[aria-label="Go to next page"]': {
            borderRadius: '0 8px 8px 0'
        }
    };
});

type TablePaginationProps = {
    rowsPerPageOptions?: DropDownChoices[];
    onRowsPerPageChange?: (rowsPerPage: number) => void | undefined;
} & MuiTablePaginationProps;

export const TablePagination = ({
    count,
    rowsPerPage,
    rowsPerPageOptions,
    onRowsPerPageChange
}: TablePaginationProps) => {
    let pageCount = Math.ceil(count / rowsPerPage);
    const navigate = useNavigate();
    const { pageNumber } = useParams();

    // const location = useLocation();

    // const locationWithoutPage = useMemo(() => {
    //     return location.pathname.substring(
    //         0,
    //         location.pathname.lastIndexOf('/page/')
    //     ); // Will break in nested cases , will handle later
    // }, [location]); // calculate only once

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: '68px',
                    alignItems: 'center',
                    marginRight: '24px',
                    marginLeft: '24px',
                    justifyContent: 'space-between',
                    boxShadow: '0px 1px 2px 0px #1018280D'
                }}
            >
                <div>
                    <Dropdown
                        type={DropdownType.SingleShowTitle}
                        direction="up"
                        label="Rows per page:"
                        choices={rowsPerPageOptions}
                        selectedOnInit={rowsPerPageOptions?.[0]}
                        value={rowsPerPage}
                        onChange={(value) => {
                            onRowsPerPageChange?.(value);
                        }}
                    />
                </div>
                <Stack direction="row">
                    {pageCount > 1 && (
                        <>
                            <Pagination
                                onChange={(event, page) => {
                                    if (page == -1) {
                                        navigate(`../${+pageNumber! - 1}`, {
                                            replace: true,
                                            relative: 'path'
                                        });
                                    } else {
                                        navigate(`../${page - 1}`, {
                                            replace: true,
                                            relative: 'path'
                                        });
                                    }
                                }}
                                count={pageCount}
                                variant="outlined"
                                page={+pageNumber! + 1}
                                renderItem={(item) => (
                                    <StyledPageItem {...item} />
                                )}
                            />
                        </>
                    )}
                </Stack>
            </div>
            <div></div>
        </>
    );
};
