import { WithConfirmationWrappedComponentProps } from '../../utils/withConfirmation';
import { useEmployeesOverview } from './EmployeesOverview';
import { useTranslation } from 'react-i18next';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import { useErrorHandling } from '../../utils/errorHandling';
import { useLoader } from '../../contexts/loader/LoaderContext';

export const useEmployeeStatusConfirmation = (
    showConfirmDialog: WithConfirmationWrappedComponentProps['showConfirmDialog'],
    reloadUsers: () => void,
    reloadCompanyUsers: () => void,
    displaySection: boolean
) => {
    const {
        onUpdateSelectedClick,
        onUserChangeStatusClick,
        onRoleToggleChange,
        onSendActivationMailClicked
    } = useEmployeesOverview();

    const { setAlert } = useNotificationsSnackbar();
    const { backendErrorHandler } = useErrorHandling();
    const { setLoading } = useLoader();

    const handleBulkEnable = (checked: string[]) => {
        showConfirmDialog(
            t('activate_user_warning'),
            t('confirm_the_action'),
            () => {
                onUpdateSelectedClick(true, checked, reloadUsers);
            }
        );
    };

    const handleBulkDisable = (checked: string[]) => {
        showConfirmDialog(
            t('disable_user_warning'),
            t('confirm_the_action'),
            () => {
                onUpdateSelectedClick(false, checked, reloadUsers);
            }
        );
    };

    const handleStatusChange = (
        userId: string,
        isActive: boolean,
        reloadUsers: () => void
    ) => {
        showConfirmDialog(
            !isActive
                ? 'Do you really want to disable the user?'
                : 'Do you really want to activate the user?',
            t('confirm_the_action'),
            () => {
                onUserChangeStatusClick(userId, isActive, reloadUsers);
            }
        );
    };

    const handleRoleToggleChange = (
        userId: string,
        isAdmin: boolean,
        reloadUsers: () => void
    ) => {
        showConfirmDialog(
            isAdmin
                ? 'do you really want to assign admin access?'
                : 'do you really want to remove admin access?',
            null,
            () => {
                onRoleToggleChange(userId, isAdmin, reloadUsers);
            }
        );
    };

    const sendActivationEmail = (
        userId: string,
        name: string,
        setError: any
    ) => {
        showConfirmDialog(
            `Are you sure that you want to send a new activation mail to ${name} ?`,
            'Send activation mail',
            () => {
                onSendActivationMailClicked(userId, setError);
            }
        );
    };

    const { t } = useTranslation();

    const handleDeleteUser = (userId: string, reloadUsers: () => void) => {
        showConfirmDialog(
            t('delete_user_confirm_message'),
            t('delete_user'),
            () => {
                UsersAPIServiceV2.getInstance()
                    .deleteUser(userId)
                    .then(() => {
                        setAlert(t('delete_user_success_message'), 'success');
                        !displaySection ? reloadUsers() : reloadCompanyUsers();
                    })
                    .catch(backendErrorHandler('Something went wrong'))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        );
    };

    return {
        handleBulkEnable,
        handleBulkDisable,
        handleStatusChange,
        handleRoleToggleChange,
        sendActivationEmail,
        handleDeleteUser
    } as const;
};
